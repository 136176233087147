import { default as _91slug_93R5Ww30w0T9Meta } from "/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/[locale]/wg2/i/frame/[slug].vue?macro=true";
import { default as indexk3pdtGUSybMeta } from "/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/[locale]/wg2/index.vue?macro=true";
import { default as index3G9jG9o9HZMeta } from "/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/[locale]/wg2/journey/index.vue?macro=true";
import { default as _91id_93hTIZqWMvAIMeta } from "/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/[locale]/wg2/journey/shared/[id].vue?macro=true";
import { default as _91bugReportQrCode_93Jpf1yViOATMeta } from "/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/bug_report/[bugReportQrCode].vue?macro=true";
import { default as _91slug_93P0NSjNWRjgMeta } from "/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/frame/[slug].vue?macro=true";
import { default as _91hash_93siXQ1huHf3Meta } from "/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/i/[hash].vue?macro=true";
import { default as indexJwXeYRSSPPMeta } from "/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/index.vue?macro=true";
import { default as infon3pZpoohDDMeta } from "/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/info.vue?macro=true";
import { default as indexHUncP9dVtnMeta } from "/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/journey/index.vue?macro=true";
import { default as _91id_93lqIxaMPFqFMeta } from "/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/journey/shared/[id].vue?macro=true";
import { default as _91hash_93siAubLUse3Meta } from "/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/li/[hash].vue?macro=true";
import { default as _91sessionHash_93fxZG50geVIMeta } from "/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/mock/channel/client/[installationPublicId]/[juaUserPublicId]/[juaUserToken]/[sessionHash].vue?macro=true";
import { default as _91installationToken_93c0um0L48V7Meta } from "/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/mock/channel/installation/[installationPublicId]/[installationToken].vue?macro=true";
import { default as onboardingI65H9UYRZmMeta } from "/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/onboarding.vue?macro=true";
import { default as _91id_93sRKeTc0VYZMeta } from "/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/s/[id].vue?macro=true";
import { default as _91id_93KETHuiCvQgMeta } from "/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/scenes/[id].vue?macro=true";
import { default as settingsMfggEZnBUKMeta } from "/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/settings.vue?macro=true";
import { default as setupWLZmflgzt4Meta } from "/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/setup.vue?macro=true";
import { default as component_45stub6zVheO3KJ8Meta } from "/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: _91slug_93R5Ww30w0T9Meta?.name ?? "locale-wg2-i-frame-slug",
    path: _91slug_93R5Ww30w0T9Meta?.path ?? "/:locale()/wg2/i/frame/:slug()",
    meta: _91slug_93R5Ww30w0T9Meta || {},
    alias: _91slug_93R5Ww30w0T9Meta?.alias || [],
    redirect: _91slug_93R5Ww30w0T9Meta?.redirect,
    component: () => import("/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/[locale]/wg2/i/frame/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexk3pdtGUSybMeta?.name ?? "locale-wg2",
    path: indexk3pdtGUSybMeta?.path ?? "/:locale()/wg2",
    meta: indexk3pdtGUSybMeta || {},
    alias: indexk3pdtGUSybMeta?.alias || [],
    redirect: indexk3pdtGUSybMeta?.redirect,
    component: () => import("/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/[locale]/wg2/index.vue").then(m => m.default || m)
  },
  {
    name: index3G9jG9o9HZMeta?.name ?? "locale-wg2-journey",
    path: index3G9jG9o9HZMeta?.path ?? "/:locale()/wg2/journey",
    meta: index3G9jG9o9HZMeta || {},
    alias: index3G9jG9o9HZMeta?.alias || [],
    redirect: index3G9jG9o9HZMeta?.redirect,
    component: () => import("/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/[locale]/wg2/journey/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93hTIZqWMvAIMeta?.name ?? "locale-wg2-journey-shared-id",
    path: _91id_93hTIZqWMvAIMeta?.path ?? "/:locale()/wg2/journey/shared/:id()",
    meta: _91id_93hTIZqWMvAIMeta || {},
    alias: _91id_93hTIZqWMvAIMeta?.alias || [],
    redirect: _91id_93hTIZqWMvAIMeta?.redirect,
    component: () => import("/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/[locale]/wg2/journey/shared/[id].vue").then(m => m.default || m)
  },
  {
    name: _91bugReportQrCode_93Jpf1yViOATMeta?.name ?? "bug_report-bugReportQrCode",
    path: _91bugReportQrCode_93Jpf1yViOATMeta?.path ?? "/bug_report/:bugReportQrCode()",
    meta: _91bugReportQrCode_93Jpf1yViOATMeta || {},
    alias: _91bugReportQrCode_93Jpf1yViOATMeta?.alias || [],
    redirect: _91bugReportQrCode_93Jpf1yViOATMeta?.redirect,
    component: () => import("/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/bug_report/[bugReportQrCode].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93P0NSjNWRjgMeta?.name ?? "frame-slug",
    path: _91slug_93P0NSjNWRjgMeta?.path ?? "/frame/:slug()",
    meta: _91slug_93P0NSjNWRjgMeta || {},
    alias: _91slug_93P0NSjNWRjgMeta?.alias || [],
    redirect: _91slug_93P0NSjNWRjgMeta?.redirect,
    component: () => import("/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/frame/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93siXQ1huHf3Meta?.name ?? "i-hash",
    path: _91hash_93siXQ1huHf3Meta?.path ?? "/i/:hash()",
    meta: _91hash_93siXQ1huHf3Meta || {},
    alias: _91hash_93siXQ1huHf3Meta?.alias || [],
    redirect: _91hash_93siXQ1huHf3Meta?.redirect,
    component: () => import("/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/i/[hash].vue").then(m => m.default || m)
  },
  {
    name: indexJwXeYRSSPPMeta?.name ?? "index",
    path: indexJwXeYRSSPPMeta?.path ?? "/",
    meta: indexJwXeYRSSPPMeta || {},
    alias: indexJwXeYRSSPPMeta?.alias || [],
    redirect: indexJwXeYRSSPPMeta?.redirect,
    component: () => import("/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: infon3pZpoohDDMeta?.name ?? "info",
    path: infon3pZpoohDDMeta?.path ?? "/info",
    meta: infon3pZpoohDDMeta || {},
    alias: infon3pZpoohDDMeta?.alias || [],
    redirect: infon3pZpoohDDMeta?.redirect,
    component: () => import("/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/info.vue").then(m => m.default || m)
  },
  {
    name: indexHUncP9dVtnMeta?.name ?? "journey",
    path: indexHUncP9dVtnMeta?.path ?? "/journey",
    meta: indexHUncP9dVtnMeta || {},
    alias: indexHUncP9dVtnMeta?.alias || [],
    redirect: indexHUncP9dVtnMeta?.redirect,
    component: () => import("/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/journey/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93lqIxaMPFqFMeta?.name ?? "journey-shared-id",
    path: _91id_93lqIxaMPFqFMeta?.path ?? "/journey/shared/:id()",
    meta: _91id_93lqIxaMPFqFMeta || {},
    alias: _91id_93lqIxaMPFqFMeta?.alias || [],
    redirect: _91id_93lqIxaMPFqFMeta?.redirect,
    component: () => import("/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/journey/shared/[id].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93siAubLUse3Meta?.name ?? "li-hash",
    path: _91hash_93siAubLUse3Meta?.path ?? "/li/:hash()",
    meta: _91hash_93siAubLUse3Meta || {},
    alias: _91hash_93siAubLUse3Meta?.alias || [],
    redirect: _91hash_93siAubLUse3Meta?.redirect,
    component: () => import("/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/li/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91sessionHash_93fxZG50geVIMeta?.name ?? "mock-channel-client-installationPublicId-juaUserPublicId-juaUserToken-sessionHash",
    path: _91sessionHash_93fxZG50geVIMeta?.path ?? "/mock/channel/client/:installationPublicId()/:juaUserPublicId()/:juaUserToken()/:sessionHash()",
    meta: _91sessionHash_93fxZG50geVIMeta || {},
    alias: _91sessionHash_93fxZG50geVIMeta?.alias || [],
    redirect: _91sessionHash_93fxZG50geVIMeta?.redirect,
    component: () => import("/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/mock/channel/client/[installationPublicId]/[juaUserPublicId]/[juaUserToken]/[sessionHash].vue").then(m => m.default || m)
  },
  {
    name: _91installationToken_93c0um0L48V7Meta?.name ?? "mock-channel-installation-installationPublicId-installationToken",
    path: _91installationToken_93c0um0L48V7Meta?.path ?? "/mock/channel/installation/:installationPublicId()/:installationToken()",
    meta: _91installationToken_93c0um0L48V7Meta || {},
    alias: _91installationToken_93c0um0L48V7Meta?.alias || [],
    redirect: _91installationToken_93c0um0L48V7Meta?.redirect,
    component: () => import("/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/mock/channel/installation/[installationPublicId]/[installationToken].vue").then(m => m.default || m)
  },
  {
    name: onboardingI65H9UYRZmMeta?.name ?? "onboarding",
    path: onboardingI65H9UYRZmMeta?.path ?? "/onboarding",
    meta: onboardingI65H9UYRZmMeta || {},
    alias: onboardingI65H9UYRZmMeta?.alias || [],
    redirect: onboardingI65H9UYRZmMeta?.redirect,
    component: () => import("/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: _91id_93sRKeTc0VYZMeta?.name ?? "s-id",
    path: _91id_93sRKeTc0VYZMeta?.path ?? "/s/:id()",
    meta: _91id_93sRKeTc0VYZMeta || {},
    alias: _91id_93sRKeTc0VYZMeta?.alias || [],
    redirect: _91id_93sRKeTc0VYZMeta?.redirect,
    component: () => import("/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/s/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93KETHuiCvQgMeta?.name ?? "scenes-id",
    path: _91id_93KETHuiCvQgMeta?.path ?? "/scenes/:id()",
    meta: _91id_93KETHuiCvQgMeta || {},
    alias: _91id_93KETHuiCvQgMeta?.alias || [],
    redirect: _91id_93KETHuiCvQgMeta?.redirect,
    component: () => import("/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/scenes/[id].vue").then(m => m.default || m)
  },
  {
    name: settingsMfggEZnBUKMeta?.name ?? "settings",
    path: settingsMfggEZnBUKMeta?.path ?? "/settings",
    meta: settingsMfggEZnBUKMeta || {},
    alias: settingsMfggEZnBUKMeta?.alias || [],
    redirect: settingsMfggEZnBUKMeta?.redirect,
    component: () => import("/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: setupWLZmflgzt4Meta?.name ?? "setup",
    path: setupWLZmflgzt4Meta?.path ?? "/setup",
    meta: setupWLZmflgzt4Meta || {},
    alias: setupWLZmflgzt4Meta?.alias || [],
    redirect: setupWLZmflgzt4Meta?.redirect,
    component: () => import("/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: component_45stub6zVheO3KJ8Meta?.name ?? undefined,
    path: component_45stub6zVheO3KJ8Meta?.path ?? "/de/wg2",
    meta: component_45stub6zVheO3KJ8Meta || {},
    alias: component_45stub6zVheO3KJ8Meta?.alias || [],
    redirect: component_45stub6zVheO3KJ8Meta?.redirect,
    component: () => import("/home/runner/work/naut-brdge-v1-hetzner/naut-brdge-v1-hetzner/frontend-wg3/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]