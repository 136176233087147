import {
  journeySchema,
  type BugReport,
  bugReportSchema,
  storyVersionSchema,
  userSchema,
} from "./types";

const API_BASE = import.meta.env.VITE_API_BASE;

const fetchWithTimeout = async (
  url: string,
  options: object,
  timeout = 3000
) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  try {
    const response = await fetch(url, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);
    return response.json();
  } catch (error) {
    clearTimeout(id);
    throw error;
  }
};

const genericOptions = {
  headers: { "Content-Type": "application/json" },
};

const genericGetRequest = async (endpoint: string, options = {}) => {
  const url = `${API_BASE}/api/${endpoint}`;
  return await fetchWithTimeout(url, { ...genericOptions, ...options });
};

const genericPostRequest = async (
  endpoint: string,
  body: object,
  options = {}
) => {
  const url = `${API_BASE}/api/${endpoint}`;
  return await fetchWithTimeout(url, {
    ...genericOptions,
    ...options,
    method: "POST",
    body: JSON.stringify(body),
  });
};

const genericDeleteRequest = async (
  endpoint: string,
  body: object,
  options = {}
) => {
  const url = `${API_BASE}/api/${endpoint}`;
  return await fetchWithTimeout(url, {
    ...genericOptions,
    ...options,
    method: "DELETE",
    body: JSON.stringify(body),
  });
};

export const get = {
  publishedStoryVersion: async (projectShortId: string) => {
    return await storyVersionSchema.validate(
      await genericGetRequest(`story_versions/published/${projectShortId}`)
    );
  },
  journey: async (journeyPublicId: string) => {
    return await journeySchema.validate(
      await genericGetRequest(`jua_journeys/${journeyPublicId}`)
    );
  },
  user: async (userPublicId: string) => {
    return await userSchema.validate(
      await genericGetRequest(`jua_users/${userPublicId}`)
    );
  },
  scanpoint: async (scanpointPublicId: string) => {
    return await genericGetRequest(`scanpoints/${scanpointPublicId}`);
  },
  installation: async (installationPublicId: string) => {
    return await genericGetRequest(`installations/${installationPublicId}`);
  },
};

export const post = {
  journeyFromPredeterminedPublicId: async (
    storyVersionPublicId: string,
    journeyPublicId: string
  ) => {
    return await journeySchema.validate(
      await genericPostRequest("jua_journeys_from_predetermined_public_id", {
        story_version_public_id: storyVersionPublicId,
        jua_journey_public_id: journeyPublicId,
      })
    );
  },
  bugReport: async (
    kind: string,
    deviceInfo: Object,
    message: string,
    tag: string
  ): Promise<BugReport> => {
    return await bugReportSchema.validate(
      await genericPostRequest(`bug_reports`, {
        kind: kind,
        device_info: deviceInfo,
        message: message,
        tag: tag,
      })
    );
  },
  installationCommand: async (hash: string, command: string, data: object) => {
    return await genericPostRequest(`installation_commands/perform`, {
      hash: hash,
      command: command,
      data: data,
    });
  },
};

export const destroy = {
  activity: async (juaActivityPublicId: string): Promise<any> => {
    await genericDeleteRequest(`jua_activities`, {
      jua_activity_public_id: juaActivityPublicId,
    });
  },
};
